import './one-food-recipe.styles.scss';
import Button from '../button/button.component';
import Spinner from '../spinner/spinner.component';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectMacros } from '../../store/macros/macros.selector';
import { selectCurrentUser } from '../../store/user/user.selector';
import Modal from '../modal/modal.component';
import { Footer } from '../footer/footer.component';
import NavBar from '../nav-bar/nav-bar.component';
import RecipeContent from './recipe-content.component';

const OneFoodRecipe = () => {

    const macros = useSelector(selectMacros);
    const currentUser = useSelector(selectCurrentUser);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ textoGenerado, setTextoGenerado ] = useState(false);
    const [texto, setTexto] = useState({});
    const [ isError, setIsError ] = useState(false);
    const token = currentUser.accessToken;
    const [isModalOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const apiUrl = process.env.REACT_APP_BACKEND_URL;
    // const apiUrlTesting = "http://localhost:8000/api/generate-recipe";
    const navigate = useNavigate();

    const toggleModal = () => {
		setModalIsOpen(!isModalOpen);
	};
    
    useEffect(() => {
        setIsError(false)
        handleSubmit();
    }, [macros]);
    
    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            setTextoGenerado(false);
          const response = await fetch(`${apiUrl}/api/generate-recipe`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': token
            },
            body: JSON.stringify({
                macros
            })
          });
            const data = await response.json();
            setTexto(data);
            setIsLoading(false);
            setTextoGenerado(true);
        } catch (err) {
            setIsLoading(false);
            setIsError(true);
            setModalMessage("Algo no ha salido como se esperaba. Inténtalo de nuevo")
        }
    };

    const regenerateRecipe = () => {
        handleSubmit();
    }

    const handleBack = () => {
        navigate(-1); // Navega a la ventana anterior
      };

    return (
        <>
            { isError && <Modal toggleModal={toggleModal} modalMessage={modalMessage} redirect='true' redirectPath='/options/one-food'/> }
            { isLoading && !textoGenerado && <Spinner texto={"Generando receta"} /> },
            { !isLoading && textoGenerado && (
                <>
                    <NavBar />
                    <div className='texto-container'>
                        <div className='texto'>
                            <RecipeContent texto={texto} />
                            <div className='buttons-container'>
                                <Button id="crear" onClick={regenerateRecipe} type="button">Regenerar dieta</Button>
                                <Button id="volver" onClick={handleBack} type="button">Volver</Button>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>)
            }   
        </>
    )
}

export default OneFoodRecipe;