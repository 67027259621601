import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateCurrentUser,
  sendEmailVerification,
  setPersistence,
  browserSessionPersistence,
  updateProfile,
  updateEmail
} from 'firebase/auth';
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID
};

const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, googleProvider);
export const db = getFirestore();

// Set session persistence
setPersistence(auth, browserSessionPersistence).catch((error) => {
  console.log('Error setting persistence:', error);
});

export const addCollectionAndDocuments = async ( collectionKey, objectsToAdd ) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });

  await batch.commit();
  console.log('done');
};

export const getCategoriesAndDocuments = async () => {
  const collectionRef = collection(db, 'categories');
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((docSnapshot) => docSnapshot.data());
};

export const createUserDocumentFromAuth = async ( userAuth, additionalInformation = {} ) => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email, emailVerified, phoneNumber, pro = false, complete = false } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        emailVerified,
        createdAt,
        phoneNumber,
        pro,
        complete,
        ...additionalInformation,
      });

      if (!emailVerified) {
        await sendEmailVerification(auth.currentUser);
      }
    } catch (error) {
      console.log('error creating the user', error.message);
    } 
  }

  return userDocRef;
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(userCredential.user); // Enviar correo de verificación
  return userCredential;
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

export const updateUser = () => updateCurrentUser(auth, auth.currentUser)
  .then(() => {
    // Profile updated!
    console.log("Profile updated!")
  }).catch((error) => {
    // An error occurred
    console.log('Error updating profile:', error)
  });

export const updateUserDocument = async (userAuth, additionalInformation = {}) => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);

  try {
    await setDoc(userDocRef, {
      ...additionalInformation
    }, { merge: true });

    // console.log('User updated:', userAuth.uid);
  } catch (error) {
    console.log('Error updating user:', error.message);
  }
};

export const updateUserProfile = async (user, updatedData) => {
  const { displayName, email } = updatedData;

  if (displayName) {
    await updateProfile(user, { displayName });
  }

  if (email) {
    await updateEmail(user, email);
  }

  await updateUserDocument(user, { displayName, email });
};

