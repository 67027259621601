import { Route, Routes } from "react-router-dom";
import GenerateRecipe from "../../components/generate-recipe/generate-recipe.component";

const OneFoodPage = () => {
    return (
        <Routes>
            <Route index element={<GenerateRecipe />} />
        </Routes>
    )
}

export default OneFoodPage;