import { Fragment } from "react";
import './footer.style.scss';

export const Footer = () =>{
    return(
		<Fragment>
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-3">
							<h4>FOOD-E</h4>
							<div className=" mt-2">
								<ul className="list-unstyled footer-list">
									<li>
										<a href="/">Home</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3">
							<h4>Information</h4>
							<div className=" mt-2">
								<ul className="list-unstyled footer-list">
									<li>
										<a href="/terms">Términos &amp; Condiciones</a>
									</li>
									<li>
										<a href="/">About us</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3">
							<h4>Support</h4>
							<div className=" mt-2">
								<ul className="list-unstyled footer-list">
									<li>
										<a href="/">FAQ</a>
									</li>
									<li>
										<a href="/contact">Contacto</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-3">
							<h4>Feedback</h4>
							<div className=" mt-2">
								<ul className="list-unstyled footer-list">
									<li>
										<a>We would like to improve more and more so you are welcome to share your ideas to make it better.</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-alt">
					<div className="container">
						<div className="row">
							<div className="col-lg-12 text-center">
								<p className="copy-rights ">Copyright © 2023 FOOD-E. Todos los derechos reservados. </p>							
							</div>
						</div>
					</div>
				</div>
			</footer>
		</Fragment>
    )
}