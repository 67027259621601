import './spinner.styles.scss';

const Spinner = ({texto}) => {
    return (
        <div className='loading-container'>
            <h2 className='mb-5'>{texto}</h2>
            <div className="spinner-border" role="status"></div>
        </div>
    )
}

export default Spinner;