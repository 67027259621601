import './easy-mode.styles.scss';
import EasyModeImg from '../../assets/webp/easy-mode.webp';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectMacros } from '../../store/macros/macros.selector';
import { useEffect, useState } from 'react';
import { setMacros } from '../../store/macros/macros.action';

const EasyMode = () => {
    const [navigateToRecipe, setNavigateToRecipe] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const macros = useSelector(selectMacros);
    const [macrosEasy, setMacrosEasy] = useState(macros);

    const handleClick = (id) => {
        const newMacros = { totales: '', hidratos: '', proteinas: '', grasas: '' };
        if (id === '1') newMacros.hidratos = '60';
        else if (id === '2') {
            newMacros.hidratos = '30';
            newMacros.proteinas = '30';
        }
        else if (id === '3') {
            newMacros.hidratos = '50';
            newMacros.proteinas = '30';
            newMacros.grasas = '20';
        }
        setMacrosEasy(newMacros);
        setNavigateToRecipe(true);
    };

    useEffect(() => {
        if (navigateToRecipe) {
            dispatch(setMacros(macrosEasy));
            navigate('./recipe');
        }
    }, [navigateToRecipe, macrosEasy, dispatch, navigate]);

    return (
        <div className='easy-mode-container'>
            <div className='easy-mode-title'>
                <img src={EasyModeImg} alt='Plate with food' />
                <div className='text-content'>
                    <h2>Tu receta en un click</h2>
                </div>
            </div>
            <span>Selecciona una opción:</span>
            <div className='easy-options-container'>
                <button onClick={() => handleClick('1')}>Alta en hidratos de carbono</button>
                <button onClick={() => handleClick('2')}>Rica en proteínas</button>
                <button onClick={() => handleClick('3')}>Equilibrada baja en grasas</button>
            </div>
        </div>
    );
}

export default EasyMode;