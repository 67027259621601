import useAuthentication from "../../hooks/useAuthentication";
import SignUpForm from "../../components/sign-up-form/sign-up-form.component";
import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import NavBar from "../../components/nav-bar/nav-bar.component";
import "./authentication.styles.scss";

const Authentication = () => {
  const { isRegistered, toggleRegistered } = useAuthentication();

  return (
    <div className="authentication-container modal-container">
      <NavBar />
      {isRegistered ? (
        <SignInForm handleRegistered={toggleRegistered} />
      ) : (
        <SignUpForm handleRegistered={toggleRegistered} />
      )}
    </div>
  );
};

export default Authentication;