import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../store/user/user.selector";
import { Footer } from "../../components/footer/footer.component";
import NavBar from "../../components/nav-bar/nav-bar.component";
import { updateUserProfile } from "../../utils/firebase/firebase.utils";
import "./user.style.scss";
import { useEffect, useState } from "react";
import Button from "../../components/button/button.component";
import UserRecipes from "../../components/user-recipes/user-recipes.component";

const UserPage = () => {
    const currentUser = useSelector(selectCurrentUser);
    const [initialDisplayName, setInitialDisplayName] = useState('');
    const [initialEmail, setInitialEmail] = useState('');
    const [displayName, setDisplayName] = useState("");
    const [email, setEmail] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const { emailVerified } = currentUser;

  useEffect(() => {
    if (currentUser) {
        const { displayName, email } = currentUser;
        setInitialDisplayName(displayName || '');
        setInitialEmail(email || '');
        setDisplayName(displayName || '');
        setEmail(email || '');
    }
  }, [currentUser]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (displayName === initialDisplayName && email === initialEmail) {
        return;
      }
    try {
      await updateUserProfile(currentUser, { displayName, email });
        alert("Perfil actualizado correctamente");
        setInitialDisplayName(displayName);
        setInitialEmail(email);
        setIsEditing(false);
    } catch (error) {
      console.error("Error al actualizar el perfil", error);
      alert("Error al actualizar el perfil");
    }
  };

  if (!currentUser) {
    return <div>No hay información de usuario</div>;
  }

  return (
    <>
      <NavBar />
      <div className="user-page">
        <div className="user-container">
          <h2>Usuario</h2>
          <form onSubmit={handleSubmit}>
            <div className="name-label">
              <label htmlFor="displayName">
                <strong>Nombre: </strong>
              </label>
              <input
                type="text"
                id="displayName"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                disabled={!isEditing}
              />
                      </div>
                      <div className="email-label">
              <label htmlFor="email">
                <strong>Email: </strong>
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!isEditing}
              />
            </div>
            <div className="email-verified-label">
              <label htmlFor="emailVerified">
                <strong>Email verificado: </strong>
              </label>
              <input
                type="text"
                id="emailVerified"
                value={emailVerified === true ? "Verificado" : "No verificado"}
                disabled
              />
            </div>
            <div>
              {isEditing ? (
                <Button type="submit" onClick={() => setIsEditing(false)}>Guardar cambios</Button>
              ) : (
                <Button onClick={() => setIsEditing(true)}>Editar</Button>
              )}
            </div>
          </form>
          {currentUser && <UserRecipes userId={currentUser.uid} />}
        </div>
        
      </div>
      <Footer />
    </>
  );
};

export default UserPage;
