import { Route, Routes } from "react-router-dom";
import Options from "../../components/options/options.component";

const OptionsMenu = () => {
    return (
        <Routes>
            <Route index element={<Options />} />
        </Routes>
    );
}

export default OptionsMenu;