import { MACROS_ACTION_TYPES } from './macros.types';

const INITIAL_STATE = {
    macros: {
        hidratos: '',
        proteinas: '',
        grasas: ''
  }
};

export const macrosReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case MACROS_ACTION_TYPES.SET_MACROS:
      return { ...state, macros: payload };
    default:
      return state;
  }
};