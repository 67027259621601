import { Link } from 'react-router-dom';
import Button from '../button/button.component';
import './options.styles.scss';

const OptionItem = ({ title, subtitle, description, buttonText, linkPath, infoPath, imgSrc, altText, comingSoon, isBlackBackground }) => {
    return (
        <div className={`options-container ${isBlackBackground ? 'black-background' : ''}`}>
            <div className={`options-container-title ${isBlackBackground ? 'black-background' : ''}`}>
                <h5>{title}</h5>
                <h4>{subtitle}</h4>
                <h2>{description}</h2>
                <div className='option-information'>
                    {comingSoon ? (
                        <h4 className='coming-soon'>PRÓXIMAMENTE</h4>
                    ) : (
                        <>
                            <Link to={linkPath} className='link'>
                                <Button>{buttonText}</Button>
                            </Link>
                            <Link to={infoPath} className='link'>
                                <h4 className='more-information'>Más información </h4>
                            </Link>
                        </>
                    )}
                </div>
            </div>
            <div className={`options-container-image ${isBlackBackground ? 'black-background' : ''}`}>
                <img src={imgSrc} alt={altText} />
            </div>
        </div>
    );
}

export default OptionItem;