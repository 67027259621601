import { Link } from 'react-router-dom';
import Button from '../button/button.component';
import FrontPageImg from '../../assets/webp/front-page.webp';
import FrontPageImg2 from '../../assets/webp/front-page2.webp';
import './front-page.styles.scss';

const FrontPage = () => {
  return (
    <div className="front-page-container">
      <div className='front-page-title'>
        <h2>Aprovecha el tiempo libre</h2>
        <h5>Deja de preocuparte en contar calorías</h5>
        <Link to='/options' className='link'>
          <Button>Comenzar</Button>
        </Link>
      </div>
      <div className='front-page-img'>
        <img src={FrontPageImg} alt='front-page' />
      </div>
      <div className='front-page-description'>
        <h2>¿Qué es FOOD-E?</h2>
        <h5>FOOD-E es una página web que utiliza inteligencia artificial para crear recetas personalizadas según tus intereses nutricionales. Ya sea que busques seguir una dieta específica, tengas alergias alimentarias o simplemente quieras probar algo nuevo, nuestra tecnología avanzada te ayudará a encontrar la receta perfecta para ti.</h5>
        <Link to='/options' className='link front-page-try'>
          <h5>¡Pruébala hoy y descubre una forma fácil y divertida de mejorar tu alimentación!</h5>
        </Link>
      </div>
      <div className='front-page-img'>
        <img src={FrontPageImg2} alt='front-page2' />
      </div>
    </div>
  );
};

export default FrontPage;