import React from "react";
import RecipeContent from "../../components/one-food-recipe/recipe-content.component";
import NavBar from "../../components/nav-bar/nav-bar.component";
import { selectUserRecipes } from "../../store/recipes/recipes.selector";
import { useSelector } from "react-redux";
import { Footer } from "../../components/footer/footer.component";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/button/button.component";

const FavoriteRecipe = () => {
  const navigate = useNavigate();
  const userRecipes = useSelector(selectUserRecipes);
  const { recipeId } = useParams();
    var recipeFavorite = {};
    
  userRecipes.forEach((recipe) => {
      if (recipe._id === recipeId) {
          recipeFavorite = recipe;
      return recipeFavorite;
    }
  });

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <NavBar />
      <div className="texto-container">
        <div className="texto">
            <RecipeContent texto={recipeFavorite} />
          <div className="buttons-container">
            <Button id="volver" onClick={handleBack} type="button">
              Volver
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FavoriteRecipe;
