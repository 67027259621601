import { ContactForm } from "../../components/contact-form/form.component";
import NavBar from "../../components/nav-bar/nav-bar.component";

const ContactPage = () => {

  return ( 
    <>
      <NavBar />
      <ContactForm />
    </>
  );
};

export default ContactPage;
