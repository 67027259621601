import NavBar from "../../components/nav-bar/nav-bar.component";
import TermsAndConditions from "../../components/terms-conditions/terms.component";


const TermsPage = () => {

  return ( 
    <>
      <NavBar />
      <TermsAndConditions />
    </>
  );
};

export default TermsPage;
