import React from 'react';
import Button from '../button/button.component';
import { useNavigate } from 'react-router-dom';
import './success-message.style.scss';

const SuccessMessage = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // Navega a la ventana anterior
    };
    
  return (
    <div className="success-message">
        <h2>Consulta enviada</h2>
        <p>Su consulta ha sido enviada y pronto recibirá una respuesta.</p>
        <Button id="volver" onClick={handleBack} type="button">Volver</Button>
    </div>
  );
};

export default SuccessMessage;
