import { Route, Routes, useNavigate } from 'react-router-dom';
import Home from './routes/home/home.route';
import OptionsMenu from './routes/options-menu/options-menu.route';
import OneFoodPage from './routes/one-food/one-food.route';
import Authentication from './routes/authentication/authentication.component';
import { onAuthStateChangedListener, createUserDocumentFromAuth, updateUserDocument } from './utils/firebase/firebase.utils';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from './store/user/user.action';
import { useEffect, useState } from 'react';
import OneFoodRecipe from './components/one-food-recipe/one-food-recipe.component';
import ContactPage from './routes/contact/contact.route';
import TermsPage from './routes/terms/terms.route';
import OneFoodInformation from './components/one-food-information/one-food-information.component';
import ScrollToTop from './components/scroll-to-top/scroll-to-tp.component';
import UserPage from './routes/user/user.route';
import ProtectedRoute from './components/protected-route/protected-route.component';
import './App.css';
import FavoriteRecipe from './routes/favorite-recipe/favorite-recipe.route';
import { selectCurrentUser } from './store/user/user.selector';
import Spinner from './components/spinner/spinner.component';

function App() { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener(async(user) => {
      if (user) {
        await createUserDocumentFromAuth(user);

        // Check if the email is verified
        if (user.emailVerified) {
          await updateUserDocument(user, { emailVerified: true });
        }

        dispatch(setCurrentUser(user));
      } else {
        dispatch(setCurrentUser(null));
      }
      setLoading(false);
    });

    return unsubscribe;
  }, [dispatch, navigate]);

  if (loading) {
    return <Spinner texto={"Cargando"} />;
  }

  return (
    <>
      <ScrollToTop />
      <h1 className="visuallyhidden">FOOD-E</h1>
      <Routes>
        <Route index element={<Home />} />
        <Route path="auth/*" element={<Authentication />} />
        <Route path="options/*" element={<OptionsMenu />} />
        <Route path="options/one-food/*" element={
          <ProtectedRoute>
            <OneFoodPage />
          </ProtectedRoute>
        } />
        <Route path="options/one-food/recipe/*" element={
          <ProtectedRoute>
            <OneFoodRecipe />
          </ProtectedRoute>
        } />
        <Route path="/recipes/:recipeId" element={
          <ProtectedRoute>
            <FavoriteRecipe />
          </ProtectedRoute>} />
        <Route path="options/one-food/one-food-information" element={<OneFoodInformation />} />
        <Route path="contact" element={<ContactPage />} />
        <Route path="terms" element={<TermsPage />} />
        <Route path="user" element={
          <ProtectedRoute>
            <UserPage />
          </ProtectedRoute>
        } />
      </Routes>
    </>
  );
}

export default App;
