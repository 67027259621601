import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '../button/button.component';
import './modal.styles.scss'

const Modal = ({ toggleModal, modalMessage, redirect, redirectPath }) => {
	// Use useEffect to add an event listener to the document
	useEffect(() => {
		function onKeyDown(event) {
			if (event.keyCode === 27) {
				// Close the modal when the Escape key is pressed
				toggleModal();
			}
		}

		// Prevent scolling
		document.body.style.overflow = "hidden";
		document.addEventListener("keydown", onKeyDown);

		// Clear things up when unmounting this component
		return () => {
			document.body.style.overflow = "visible";
			document.removeEventListener("keydown", onKeyDown);
		};
	});

	return (
		<div className="modal__backdrop">
			<div className="modal__container">
                <h5 className="modal__title">{modalMessage}</h5>
                {redirect ?
                    <Link to={redirectPath}>
                        <Button type="button" onClick={toggleModal}>
                            Cerrar
                        </Button>
                    </Link>
                    :
                    <Button type="button" onClick={toggleModal}>
                        Cerrar
				    </Button>
                }
				
			</div>
		</div>
	);
};

export default Modal;