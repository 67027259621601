import { useState } from "react";

const useAuthentication = () => {
  const [isRegistered, setIsRegistered] = useState(true);

  const toggleRegistered = () => {
    setIsRegistered(prevState => !prevState);
  };

  return { isRegistered, toggleRegistered };
};

export default useAuthentication;