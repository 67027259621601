import React from 'react';
import './one-food-information.styles.scss';
import NavBar from '../nav-bar/nav-bar.component';
import Button from '../button/button.component';
import { useNavigate } from 'react-router-dom';

const OneFoodInformation = () => {
    const nombre = "Food-e";
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // Navega a la ventana anterior
    };
    return (
      <>
        <NavBar />
        <div className="one-food-information">
          <h1>Generador de Dietas Personalizadas</h1>
          <p>
            Bienvenido a {nombre}, tu asistente de nutrición impulsado por
            inteligencia artificial. En esta página, podrás generar dietas
            personalizadas en función de las opciones preestablecidas que ofrece
            nuestra aplicación web.
          </p>
          <h2>¿Cómo Funciona?</h2>
          <p>
            Nuestra aplicación utiliza avanzados algoritmos de inteligencia
            artificial para crear planes de dieta adaptados a tus necesidades y
            preferencias. Simplemente selecciona tus opciones preferidas y
            nuestro generador de dietas hará el resto.
          </p>
          <h2>Opciones Disponibles</h2>
          <p>Podrás elegir entre una variedad de opciones que incluyen:</p>
          <ul>
            <li>
              Objetivos nutricionales (pérdida de peso, mantenimiento, ganancia
              muscular, etc.)
            </li>
            <li>
              Preferencias alimenticias (vegetariano, vegano, sin gluten, etc.)
            </li>
            <li>Restricciones dietéticas (alergias, intolerancias, etc.)</li>
            <li>Frecuencia de comidas (número de comidas al día)</li>
            <li>Tipos de cocina (mediterránea, asiática, occidental, etc.)</li>
          </ul>
          <h2>Comienza Ahora</h2>
          <p>
            ¡Empieza hoy a llevar un estilo de vida más saludable con {nombre}!
            </p>
                <Button id="volver" onClick={handleBack} type="button">Volver</Button>
        </div>
      </>
    );
};

export default OneFoodInformation;
