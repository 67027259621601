import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';
import {
  createAuthUserWithEmailAndPassword,
  createUserDocumentFromAuth,
} from '../../utils/firebase/firebase.utils';
import './sign-up-form.styles.scss';

const defaultFormFields = {
  displayName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

const SignUpForm = ({ handleRegistered }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { displayName, email, password, confirmPassword } = formFields;
  const [signupErrorMessage, setSignupErrorMessage] = useState("");
  const navigate = useNavigate();
  
  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setSignupErrorMessage("La contraseña no coincide.")
      return;
    }

    try {
      const { user } = await createAuthUserWithEmailAndPassword(
        email,
        password
      );

      await createUserDocumentFromAuth(user, { displayName })
      navigate("/options");
      resetFormFields();
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setSignupErrorMessage("Ya existe un usuario con este email.")
      } else {
        setSignupErrorMessage("Ha ocurrido un error al crear el usuario.")
        console.log(error);
      }
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div className='sign-up-container'>
      <h2>¿No tienes cuenta aún?</h2>
      <span>Regístrate gratis con tu email y contraseña.</span>
      <span>Si ya estás registrado puedes <span type='button' className='anchor-init-sesion' onClick={handleRegistered}>iniciar sesión.</span></span>
      <form onSubmit={handleSubmit}>
        <FormInput
          label='Nombre'
          type='text'
          required
          onChange={handleChange}
          name='displayName'
          value={displayName}
        />

        <FormInput
          label='Email'
          type='email'
          required
          onChange={handleChange}
          name='email'
          value={email}
        />

        <FormInput
          label='Contraseña'
          type='password'
          required
          onChange={handleChange}
          name='password'
          value={password}
        />

        <FormInput
          label='Confirmar Contraseña'
          type='password'
          required
          onChange={handleChange}
          name='confirmPassword'
          value={confirmPassword}
        />
        {signupErrorMessage &&
          <div className='password-message'>
            <span >{ signupErrorMessage }</span>
          </div>
        }
        <div className='buttons-container'>
          <Button type='submit'>Sign Up</Button>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;