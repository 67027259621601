import { RECIPES_ACTION_TYPES } from './recipes.types';

const INITIAL_STATE = {
  userRecipes: [],
};

export const recipesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RECIPES_ACTION_TYPES.SET_USER_RECIPES:
      return { ...state, userRecipes: payload };
    default:
      return state;
  }
};
