import { useState } from 'react';
import './generate-recipe.styles.scss';
import NavBar from '../nav-bar/nav-bar.component.jsx';
import EasyMode from '../easy-mode/easy-mode.component';
import { Footer } from '../footer/footer.component';
import ProMode from '../pro-mode/pro-mode.component';

const GenerateRecipe = () => {
    const [isSimpleMode, setSimpleMode] = useState(true);

    return (
        <div className='generate-recipe-container' >
            <NavBar />
            <div className='mode-container'>
                <h3 className='easy-mode' type='button' onClick={() => setSimpleMode(true)}>
                    Modo sencillo
                </h3>
                <h3 className='complete-mode' type='button' onClick={() => setSimpleMode(false)}>
                    Modo pro
                </h3>
            </div>
            {isSimpleMode ? 
                <EasyMode /> 
                :
                <ProMode />
            }
            <Footer />
        </div>
    )
}

export default GenerateRecipe;