import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMacros } from '../../store/macros/macros.action';
import Button from '../button/button.component';
import FormInput from '../form-input/form-input.component';
import Modal from '../modal/modal.component';
import ProModeImg from '../../assets/webp/pro-mode.webp';
import './pro-mode.styles.scss';

const ProMode = () => {

    const [macrosInputs, setMacrosInputs] = useState({ totales: '', hidratos: '', proteinas: '', grasas: '' });
    const { totales, hidratos, proteinas, grasas } = macrosInputs;
    const [isModalOpen, setModalIsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
	
	const toggleModal = () => {
		setModalIsOpen(!isModalOpen);
	};

    const handleChange = (event) => {
        const { name, value } = event.target;
        setMacrosInputs({ ...macrosInputs, [name]: value });   
    }

    const validateInputs = () => {
        if (Number(totales) < 10) return "El total de calorías debe ser mayor a 10Cal.";
        if (Number(totales) > 5000) return "El total de calorías debe ser menor a 5000Cal.";
        if (Number(hidratos) + Number(proteinas) + Number(grasas) !== 100) return "La suma de 'Hidratos de carbono', 'Proteínas' y 'Grasas' tiene que ser igual a 100.";
        if (!hidratos || !proteinas || !grasas) return "Por favor, rellena todos los campos.";
        if (Number(hidratos) < 0 || Number(proteinas) < 0 || Number(grasas) < 0) return "No puede ingresar números negativos.";
        return null;
    };

    const handleSubmit = () => {
        const validationMessage = validateInputs();
        if (validationMessage) {
            setModalMessage(validationMessage);
            setModalIsOpen(true);
            return;
        }
        dispatch(setMacros(macrosInputs));
        navigate('./recipe');
    };

    return (
        <>
            {isModalOpen && <Modal toggleModal={toggleModal} modalMessage={modalMessage} />}
            <div className='pro-mode-container'>
                <div className='pro-mode-title'>
                    <div className='text-content'>
                        <h2>Receta nutricional personal</h2>
                    </div>
                    <img src={ProModeImg} alt='Plate with food in pro mode' />
                </div>
                <div className='pro-options-container'>
                    <div className='totales-container'>
                        <h4>¿Cuántas calorías quieres que contenga la receta?</h4>
                        <div className='totales-input'>
                            <FormInput
                                label='Calorías totales'
                                type="number"
                                required
                                onChange={handleChange}
                                name='totales'
                                value={totales}
                            />
                        </div> 
                    </div>
                    <div className='macros-container'>
                        <h4>Ajusta el porcentaje de macronutrientes</h4>
                        <div className='macros-inputs'>
                            <FormInput
                                label='% Hidratos de carbono'
                                type="number"
                                required
                                onChange={handleChange}
                                name='hidratos'
                                value={hidratos}
                            />
                            <FormInput
                                label='% Proteínas'
                                type="number"
                                required
                                onChange={handleChange}
                                name='proteinas'
                                value={proteinas}
                            />
                            <FormInput
                                label='% Grasas'
                                type="number"
                                required
                                onChange={handleChange}
                                name='grasas'
                                value={grasas}
                            />
                        </div>
                    </div>
                </div>
                <Button id="crear" onClick={handleSubmit} type="submit">Crear dieta</Button>   
            </div>
        </>
    )
}

export default ProMode;