import './options.styles.scss';
import NavBar from '../nav-bar/nav-bar.component';
import { Footer } from '../footer/footer.component'; // Asegúrate de que Footer no esté exportado como named export
import OneFood from '../../assets/webp/unico-plato.webp';
import DayFood from '../../assets/webp/day-food.webp';
import WeekFood from '../../assets/webp/week-food.webp';
import OptionItem from '../options/options-item.component';

const Options = () => {

    return (
        <>
            <NavBar />
            <div className='option-menu-container'>
                <OptionItem
                    title="Gratis"
                    subtitle="Receta poco configurable"
                    description="Más fácil que nunca"
                    buttonText="Comenzar"
                    linkPath='./one-food'
                    infoPath="./one-food/one-food-information"
                    imgSrc={OneFood}
                    altText="One food"
                />
                <OptionItem
                    title="Subscripción Pro"
                    subtitle="Especifica los macronutrientes"
                    description="Sencillo pero potente"
                    buttonText="Comenzar"
                    linkPath="./one-food"
                    infoPath="./one-food"
                    imgSrc={DayFood}
                    altText="Day food"
                    isBlackBackground
                />
                <OptionItem
                    title="Subscripción Completa"
                    subtitle="Receta para un día completo"
                    description="Ajusta tu día nutricionalmente"
                    comingSoon
                    imgSrc={WeekFood}
                    altText="Week food"
                />
            </div>
            <Footer />
        </>
    );
}

export default Options;