import React, { useState, useEffect } from 'react';
import Modal from '../modal/modal.component';
import './one-food-recipe.styles.scss';
import { selectCurrentUser } from '../../store/user/user.selector';
import { useSelector } from 'react-redux';

const RecipeContent = ({ texto }) => {
  const { nombre, ingredientes, preparacion, valor_nutricional, _id } = texto;
  const [isHeartFilled, setIsHeartFilled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const currentUser = useSelector(selectCurrentUser);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const checkIfFavorite = async () => {
      if (currentUser) {
        try {
          const response = await fetch(`${apiUrl}/api/check-favorite?userId=${currentUser.uid}&recipeId=${_id}`);
          if (response.ok) {
            const { isFavorite } = await response.json();
            setIsHeartFilled(isFavorite);
          }
        } catch (error) {
          console.error('Error checking favorite status:', error);
        }
      }
    };

    checkIfFavorite();
  }, [currentUser, _id]);

  const toggleHeart = async () => {
    const newIsHeartFilled = !isHeartFilled;
    setIsHeartFilled(newIsHeartFilled);

    const action = newIsHeartFilled ? 'añadido a favoritos' : 'eliminado de favoritos';
    setModalMessage(`Se ha ${action}`);

    try {
      const endpoint = newIsHeartFilled ? 'add-recipe' : 'remove-recipe';
      const recipeWithUser = {
        ...texto,
        user_id: currentUser.uid,
      };

      const response = await fetch(`${apiUrl}/api/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(recipeWithUser)
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      setIsModalOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setModalMessage(error.message === 'Se ha alcanzado el máximo de recetas guardadas.' ? error.message : 'Error al actualizar los favoritos');
      if (error.message === 'Se ha alcanzado el máximo de recetas guardadas.') {
        setIsHeartFilled(false);  // Revertir el estado del corazón
      }
      setIsModalOpen(true);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(false);
  };

  if (!ingredientes) {
    return <p>Hubo un error al cargar la receta. Inténtalo de nuevo.</p>;
  }

  return (
    <>
      <div className='nombre-container'>
        <h2>{nombre}</h2>
      </div>
      <div className='ingredientes-container'>
        <h4>Estos son los ingredientes que necesitas:</h4>
        <div className='lista-ingredientes'>
          <ul>
            {Object.entries(ingredientes).map(([key, value]) => (
              <li key={key}>{key}: {value}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className='preparacion-container'>
        <h4>Y así es como se prepara:</h4>
        <p>{preparacion}</p>
      </div>
      <div className='valor-container'>
        <h4>Valor nutricional:</h4>
        <p>{valor_nutricional}</p>
      </div>
      <div className='heart-icon' onClick={toggleHeart}>
        {isHeartFilled ? '❤️' : '🤍'}
      </div>
      {isModalOpen && (
        <Modal 
          toggleModal={toggleModal} 
          modalMessage={modalMessage} 
          redirect={false} 
        />
      )}
    </>
  );
};

export default RecipeContent;
