import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormInput from '../form-input/form-input.component';
import Button from '../button/button.component';
import Modal from '../modal/modal.component';
import { signInAuthUserWithEmailAndPassword, signInWithGooglePopup } from '../../utils/firebase/firebase.utils';
import './sign-in-form.styles.scss';

const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = ({ handleRegistered }) => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;
  const navigate = useNavigate();
  const [isModalOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithGooglePopup();
      navigate("/options");
    } catch (error) {
      setIsError(true);
      setModalMessage("Algo ha salido mal. Inténtelo de nuevo.");
      setModalIsOpen(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      navigate("/options");
      resetFormFields();
    } catch (error) {
      setIsError(true);
      setModalMessage("Algo ha salido mal. Inténtelo de nuevo.");
      setModalIsOpen(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const toggleModal = () => {
    setModalIsOpen(!isModalOpen);
    setIsError(!isError);
  };

  return (
    <>
      {isModalOpen && <Modal toggleModal={toggleModal} modalMessage={modalMessage} redirect={false} />}
      <div className='sign-in-container'>
        <h2>¿Ya estás registrado?</h2>
        <span>Inicia sesión con email y contraseña.</span>
        <span>
          Si no tienes cuenta aún puedes registrarte gratis{' '}
          <span type='button' className='anchor-register' onClick={handleRegistered}>
            aquí.
          </span>
        </span>
        <form onSubmit={handleSubmit}>
          <FormInput
            label='Email'
            type='email'
            required
            onChange={handleChange}
            name='email'
            value={email}
          />
          <FormInput
            label='Password'
            type='password'
            required
            onChange={handleChange}
            name='password'
            value={password}
          />
          <div className='buttons-container'>
            <Button type='submit'>Sign In</Button>
            <Button buttonType='google' type='button' onClick={signInWithGoogle}>
              Sign In With Google
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SignInForm;