import Button from '../button/button.component';
import './form.style.scss';
import React, { useState } from 'react';
import SuccessMessage from '../success-message/success-message.component';

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí puedes manejar el envío del formulario, por ejemplo, enviarlo a un servidor.
      console.log('Formulario enviado', formData);
      setIsSubmitted(true);
  };

  return (
      <div className="contact-form">
          {isSubmitted ? (
              <SuccessMessage />
          ) : (
            <>
            <h1>Formulario de Contacto</h1>
            <form onSubmit={handleSubmit}>
                <div>
                <label htmlFor="name">Nombre:</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                </div>
                <div>
                <label htmlFor="email">Email:</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
                </div>
                <div>
                <label htmlFor="message">Consulta:</label>
                <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                />
                </div>
                    <Button type='submit'>Enviar</Button>
                      </form>
            </>
          )}
    </div>
  );
}
