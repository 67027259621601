import { combineReducers } from 'redux';
import { userReducer } from '../store/user/user.reducer';
import { macrosReducer } from './macros/macros.reducer';
import { recipesReducer } from './recipes/recipes.reducer';


export const rootReducer = combineReducers({
  user: userReducer,
  macros: macrosReducer,
  recipes: recipesReducer,
});
