import { NavLink } from 'react-router-dom';
import SignInIcon from '../sign-in-icon/sign-in-icon.component';
import Logo from '../../assets/webp/logo.webp';
import './nav-bar.styles.scss';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../store/user/user.selector';
import UserIcon from '../../assets/icons/user-icon.svg';

const NavBar = () => {
    const currentUser = useSelector(selectCurrentUser);
    
    return (
        <nav className='nav-bar'>
            <div className='logo-container'>
                <img src={Logo} alt='logo' />
                <h3><NavLink to='/'>FOOD-E</NavLink>
                </h3>
            </div>
            <div className={currentUser ? 'nav-container-logo' : 'nav-container'}>
                <NavLink className="navlink" to="/options">INICIO</NavLink>
                <SignInIcon />
                {currentUser && (
                    <NavLink to='/user' className='navlink'>
                        <img className='user-icon' src={UserIcon} alt='logo' />
                    </NavLink>
                )}
            </div>
        </nav>
    );
}

export default NavBar;