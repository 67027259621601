import NavBar from '../../components/nav-bar/nav-bar.component';
import FrontPage from '../../components/front-page/front-page.component';
import { Footer } from '../../components/footer/footer.component';

const Home = () => {
  return (
    <>
      <NavBar />
      <FrontPage />
      <Footer />
    </>
  );
};

export default Home;