import React from 'react';
import { useNavigate } from 'react-router-dom';
import './terms.style.scss';
import Button from '../button/button.component';

const TermsAndConditions = () => {
    const nombre = "Food-e";
    const URL = "www.food-e.es";
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1); // Navega a la ventana anterior
    };
  return (
    <div className="terms-and-conditions">
      <h1>Términos y Condiciones</h1>
      <p>Bienvenido a {nombre}. Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de {nombre}, ubicado en {URL}.</p>

      <h2>1. Aceptación de los Términos</h2>
      <p>Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones. No continúes usando {nombre} si no estás de acuerdo con todos los términos y condiciones establecidos en esta página.</p>

      <h2>2. Uso de la Aplicación</h2>
      <p>{nombre} ofrece una aplicación web que genera recetas utilizando inteligencia artificial. Al usar esta aplicación, reconoces y aceptas que las recetas generadas son sugerencias y deben ser evaluadas y adaptadas a tus necesidades y restricciones dietéticas específicas. {nombre} no garantiza la exactitud, integridad o idoneidad de las recetas generadas.</p>

      <h2>3. Cookies</h2>
      <p>El sitio web utiliza cookies para personalizar y mejorar la experiencia de los usuarios. Al acceder a {nombre}, aceptaste utilizar las cookies necesarias.</p>

      <h2>4. Licencia</h2>
      <p>A menos que se indique lo contrario, {nombre} y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en {nombre}. Todos los derechos de propiedad intelectual están reservados. Puedes acceder a esto desde {nombre} para tu uso personal sujeto a las restricciones establecidas en estos términos y condiciones.</p>

      <h2>5. Uso Aceptable</h2>
      <p>No debes:</p>
      <ul>
        <li>Copiar o republicar material de {nombre}.</li>
        <li>Vender, alquilar o sublicenciar material de {nombre}.</li>
        <li>Reproducir, duplicar o copiar material de {nombre}.</li>
        <li>Redistribuir contenido de {nombre}.</li>
      </ul>

      <h2>6. Comentarios de los Usuarios</h2>
      <p>Partes de este sitio web ofrecen la oportunidad a los usuarios de publicar e intercambiar opiniones e información en ciertas áreas del sitio. {nombre} no filtra, edita, publica ni revisa los comentarios antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista y opiniones de {nombre}, sus agentes y/o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica sus puntos de vista y opiniones.</p>

      <h2>7. Enlaces a Otros Sitios Web</h2>
      <p>Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por {nombre}. {nombre} no tiene control sobre, y no asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios web o servicios de terceros.</p>

      <h2>8. Limitación de Responsabilidad</h2>
      <p>En ningún caso, {nombre}, ni sus directores, empleados o agentes, serán responsables por cualquier daño indirecto, incidental, especial, consecuente o punitivo, incluyendo sin limitación, pérdida de beneficios, datos, uso, buena voluntad u otras pérdidas intangibles, resultantes de (I) tu acceso o uso o incapacidad para acceder o usar el Servicio; (II) cualquier conducta o contenido de terceros en el Servicio; (III) cualquier contenido obtenido del Servicio; y (IV) acceso, uso o alteración no autorizados de tus transmisiones o contenido, ya sea basado en garantía, contrato, agravio (incluyendo negligencia) o cualquier otra teoría legal, independientemente de si hemos sido informados de la posibilidad de dichos daños, e incluso si se encuentra que un remedio establecido en el presente documento ha fallado en su propósito esencial.</p>

      <h2>9. Cambios</h2>
      <p>Nos reservamos el derecho, a nuestra entera discreción, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es material, intentaremos proporcionar al menos 30 días de aviso antes de que cualquier nuevo término tome efecto. Lo que constituye un cambio material será determinado a nuestra sola discreción.</p>

      <h2>10. Contacto</h2>
          <p>Si tienes alguna pregunta sobre estos Términos, por favor contáctanos en el apartado de.</p>
          <Button id="volver" onClick={handleBack} type="button">Volver</Button>
    </div>
  );
};

export default TermsAndConditions;
