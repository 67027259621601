import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../spinner/spinner.component';
import './user-recipes.styles.scss';
import { Link } from 'react-router-dom';
import { setUserRecipes } from '../../store/recipes/recipes.action';
import { selectUserRecipes } from '../../store/recipes/recipes.selector';

const UserRecipes = ({ userId }) => {
    const dispatch = useDispatch();
    const recipes = useSelector(selectUserRecipes);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const apiUrl = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchRecipes = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${apiUrl}/api/recipes/${userId}`);
                if (!response.ok) throw new Error('Failed to fetch recipes');
                const data = await response.json();
                dispatch(setUserRecipes(data.recipes));
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchRecipes();
    }, [userId, dispatch]);

    if (loading) return <Spinner texto={"Buscando recetas favoritas"} />;
    if (error) return <div>Ha ocurrido un error al cargar las recetas.</div>;

    return (
        <div className='recipe-content'>
            <h3>Tus recetas favoritas</h3>
            { !recipes ? (
                    <div className="no-recipe">
                        No se encontró ninguna receta favorita.
                    </div>
                ) : ( <ul className='lista-recetas'>
                    { recipes.map(recipe => (
                            <li className='receta-favorita' key={recipe._id}>
                                <Link to={{
                                    pathname: `/recipes/${recipe._id}`,
                                    state: { recipe }
                                }}>
                                    {recipe.nombre}
                                </Link>
                            </li>
                        ))
                    }
            </ul>
                    )
                }
        </div>
    );
};

export default UserRecipes;