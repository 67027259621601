import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { selectCurrentUser } from '../../store/user/user.selector';
import { signOutUser } from "../../utils/firebase/firebase.utils";
import './sign-in-icon.styles.scss';

const SignInIcon = () => {
    const currentUser = useSelector(selectCurrentUser);
    return (
        <>
            {
                currentUser ? (
                    <NavLink className="navlink" to="/auth" onClick={signOutUser}>
                        SIGN OUT
                    </NavLink >
                ) : (
                    <NavLink className="navlink" to="/auth" onClick={signOutUser}>
                        SIGN IN
                    </NavLink >
                )
            }

        </>
    )
}

export default SignInIcon;